export const languages = [
  {
    label: "English",
    code: "en",
    flag: "us.svg",
  },
  {
    label: "French",
    code: "fr",
    flag: "fr.svg",
  },
];

