import english from "./translations/en.json";
import french from "./translations/fr.json";
import { languages } from "./languages.js";

export const setLanguage = (lang) => {
  localStorage.setItem("country", JSON.stringify(lang));
  localStorage.setItem("lang", lang.code);
  location.reload();
};

export const getCountry = () => {
  var country = localStorage.getItem("country");
  if (country) {
    country = JSON.parse(country);
  } else {
    country = languages[0];
    localStorage.setItem("country", JSON.stringify(country));
    localStorage.setItem("lang", this.country.code);
  }
  return country;
};

export const getLanguage = () => {
  let lang = localStorage.getItem("lang") || "en";
  return lang == "en" ? english : french;
};
