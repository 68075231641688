import { Printd } from "printd";
export default {
  install(Vue) {
    Vue.prototype.$helper = {
      timeZone: localStorage.getItem("_tz") || "Africa/Kigali",
      baseUrl(tail = null) {
        let result = location.origin;
        if (tail) result += `/${tail}`;
        return result;
      },
      formatNumber(number) {
        if (!number) {
          return 0;
        }
        let str = number.toString();
        const decimalIndex = str.indexOf(".");
        const decimalPlaces = 3;
        if (decimalIndex !== -1) {
          // Limit the decimal places without rounding
          const limitedDecimal = str.substr(decimalIndex + 1, decimalPlaces);
          // Construct the resulting string
          str = str.substr(0, decimalIndex + 1) + limitedDecimal;
        }
        return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      reloadPage() {
        location.reload();
      },
      showFilePicker(inputFileId) {
        document.getElementById(inputFileId).click();
      },
      isEmpty(obj) {
        const isNULL = Object.values(obj).every((x) => x === null);
        return Object.keys(obj).length === 0 || isNULL === true;
      },
      empty(mixedVar) {
        let undef, key, i, len;
        const emptyValues = [undef, null, false, 0, "", "0"];
        for (i = 0, len = emptyValues.length; i < len; i++) {
          if (mixedVar === emptyValues[i]) {
            return true;
          }
        }
        if (typeof mixedVar === "object") {
          for (key in mixedVar) {
            if (Object.prototype.hasOwnProperty.call(mixedVar, key)) {
              return false;
            }
          }
          return true;
        }
        return false;
      },
      handleArrayPush(array, newItem) {
        return [...[newItem], ...array];
      },
      handlePrint(elt) {
        const d = new Printd();
        const css = [
          `${location.origin}/css/styles.min.css`,
          `${location.origin}/css/custom.min.css`,
        ];

        d.print(document.querySelector(elt), css);
      },
      resetObjectValues(obj) {
        for (let key in obj) {
          if (obj[key] === true) obj[key] = false;
          else if (obj[key] === false) continue;
          else obj[key] = null;
        }
        return obj;
      },
      formatDate(str) {
        if (!str) {
          return "--";
        }
        const options = {
          month: "short",
          day: "numeric",
          year: "numeric",
          timeZone: this.timeZone,
        };
        const today = new Date(str);
        return today.toLocaleDateString("en-US", options);
      },
      formatTime(str) {
        return new Date(str).toLocaleTimeString("en-US", {
          timeZone: this.timeZone,
        });
      },
      generateFormData(obj) {
        let formData = new FormData();
        for (let key in obj) {
          if (obj[key] !== null && typeof obj[key] !== "undefined") {
            if (typeof obj[key] === "object")
              formData.append(key, JSON.stringify(obj[key]));
            else formData.append(key, obj[key]);
          }
        }
        return formData;
      },
      toggleModalOpen() {
        const elt = document.documentElement;
        if (elt.classList.contains("modal-open"))
          elt.classList.remove("modal-open");
        else elt.classList.add("modal-open");

        const el = document.body;
        if (el.classList.contains("modal-open"))
          el.classList.remove("modal-open");
        else el.classList.add("modal-open");
      },
      encodeQuery(url, data) {
        let query = "";
        for (let d in data) {
          if (
            !this.empty(data[d]) &&
            url.indexOf(`?${d}`) < 0 &&
            url.indexOf(`&${d}`) < 0
          )
            query +=
              encodeURIComponent(d) + "=" + encodeURIComponent(data[d]) + "&";
        }
        return url.indexOf("?") > -1
          ? `${url}&${query.slice(0, -1)}`
          : `${url}?${query.slice(0, -1)}`;
      },
      generateVoucherNo(no) {
        let len = no.toString().length;
        if (len >= 4) return no;
        if (len == 1) return `000${no}`;
        if (len == 2) return `00${no}`;
        if (len == 3) return `0${no}`;
      },
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      inputTitle(text) {
        return text.split("_").join(" ");
      },
      createTitleSlug(text) {
        return text.toLowerCase().split(" ").join("_");
      },
      createKeyPath(keys = []) {
        return keys.join(".");
      },
      getKeyValue(path, obj) {
        return path.split(".").reduce((p, c) => (p && p[c]) || null, obj);
      },
      incrementObjectKey(obj, key, max = null) {
        if (max) {
          if (Number(obj[key]) <= max) {
            obj[key] = Number(obj[key]) + 1;
          }
        } else {
          obj[key] = Number(obj[key]) + 1;
        }
      },
      decrementObjectKey(obj, key, min = 1) {
        if (Number(obj[key]) > min) {
          obj[key] = Number(obj[key]) - 1;
        }
      },
      capitalizify(phrase) {
        return phrase
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      },
      abbreviateNumber(e) {
        if (999 < Math.abs(e) && Math.abs(e) < 999999) {
          return `${Math.sign(e) * Number((Math.abs(e) / 1e3).toFixed(1))}K`;
        } else if (999999 < Math.abs(e) && Math.abs(e) < 999999999) {
          return `${Math.sign(e) * Number((Math.abs(e) / 1e6).toFixed(1))}M`;
        } else if (999999999 < Math.abs(e) && Math.abs(e) < 999999999999) {
          return `${Math.sign(e) * Number((Math.abs(e) / 1e9).toFixed(1))}B`;
        } else if (999999999999 < Math.abs(e)) {
          return `${Math.sign(e) * Number((Math.abs(e) / 1e12).toFixed(1))}T`;
        } else {
          return `${Math.sign(e) * Math.abs(e)}`;
        }
      },
      generateRandomText(length) {
        let result = "";
        const characters =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          result += characters.charAt(randomIndex);
        }
        return result;
      },
      createWhitespace(length) {
        return "&nbsp;".repeat(length);
      },
      getMonthBoundaries() {
        // Get the current date
        const currentDate = new Date(TODAY);
        // Get the start of the month
        const startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        )
          .toLocaleString("fr-CA", { timeZone: TIMEZONE })
          .slice(0, 10);

        // Get the end of the month
        const endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        )
          .toLocaleString("fr-CA", { timeZone: TIMEZONE })
          .slice(0, 10);

        return [startDate, endDate];
      },
      handlePrintFrame(_node, landscape = false) {
        const node = document.querySelector(_node);
        const printFrame = document.querySelector("#printFrame");
        let css = "";
        const styles = document.querySelectorAll("style");
        for (let i = 0; i < styles.length; i++) {
          css += styles[i].innerHTML;
        }
        let sheet = "";
        const stylesheets = document.querySelectorAll('link[rel="stylesheet"]');
        for (let i = 0; i < stylesheets.length; i++) {
          sheet += '<link rel="stylesheet" href="' + stylesheets[i].href + '">';
        }
        css +=
          "@page { size: " +
          (landscape ? "landscape" : "A4") +
          "; margin: 10mm; }" +
          "html,body { overflow: visible !important; }" +
          ".print_report.v-application { display: block; overflow: visible; }" +
          ".print_report.v-application .v-application--wrap { display: block; }";
        const doc = printFrame.contentDocument;
        doc.open();
        doc.write(
          '<!DOCTYPE html><head><meta charset="utf-8"><meta http-equiv="X-UA-Compatible" content="IE=edge"><meta name="viewport" content="width=device-width,initial-scale=1.0">' +
            sheet +
            '<style type="text/css">' +
            css +
            "</style></head>"
        );
        const signature = `
          <div class="nk-notes mt-5 d-print">
                  <div class="row">
                    <div class="col-6">
                      <div class="d-flex w-100 flex-nowrap mb-5">
                        <h6 class="mb-0">
                          <b>Prepared by:</b>
                        </h6>
                        <div class="dots-after"></div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="d-flex w-100 flex-nowrap mb-5">
                        <h6 class="mb-0">
                          <b>Reviewed by:</b>
                        </h6>
                        <div class="dots-after"></div>
                      </div>
                    </div>

                    <div class="col-6"></div>
                    <div class="col-6">
                      <div class="d-flex w-100 flex-nowrap mb-5">
                        <h6 class="mb-0">
                          <b>Approved By:</b>
                        </h6>
                        <div class="dots-after"></div>
                      </div>
                    </div>
                  </div>
                </div>
        `;
        // we have to wait until the Material Design icons have been loaded before printing
        doc.write(
          '<body onload="window.print();"><div class="v-application v-application--is-ltr theme--light print_report">' +
            node.outerHTML +
            signature +
            "</div></body></html>"
        );
        doc.close();
      },
    };
  },
};
