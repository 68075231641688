<template>
  <bootstrap-modal
    @close="$emit('closed')"
    style="display: block"
    :modalSize="'default'"
    :headerColor="'#fff'"
    :show-footer="false"
  >
    <span slot="head" style="padding-top: 10px">Change Branch</span>
    <div slot="body">
      <div class="branches_search">
        <form class="">
          <div class="iconContainer">
            <div class="icon-search"></div>
          </div>
          <input
            type="text"
            placeholder="Search for a branch"
            v-model="searchKey"
          />
        </form>
      </div>
      <div class="branches_list">
        <ul class="list-group list-group-flush my--1">
          <li
            class="list-group-item pl-2"
            v-for="(branch, index) in filteredBranches"
            :key="'categoryTable' + index"
            @click="storeCurrentBranch(branch)"
          >
            <div class="row align-items-center">
              <div class="col-auto pr-1">
                <!-- Avatar -->
                <a
                  href="javascript:void(0)"
                  class="avatar rounded bg-white"
                  style="width: 38px; height: 38px"
                >
                  <img
                    :alt="branch.name"
                    :src="`${publicPath}img/icons8_company_500px.png`"
                    class="img rounded img-fluid"
                    style="width: 38px"
                  />
                </a>
              </div>
              <div class="col-9">
                <span class="mb-0">
                  <a href="javascript:void(0)">{{ branch.name }}</a>
                </span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </bootstrap-modal>
</template>
<script>
import { mapState } from "vuex";
import BootstrapModal from "@/components/BootstrapModal.vue";
export default {
  name: "BranchSelector",
  components: { BootstrapModal },
  data: () => ({
    searchKey: null,
  }),

  computed: {
    ...mapState({
      branches: (state) => state.branches,
    }),
    filteredBranches: function () {
      let searchKey = this.searchKey && this.searchKey.toLowerCase();
      let branches = this.branches;
      if (searchKey) {
        branches = branches.filter((row) => {
          return Object.keys(row).some((key) => {
            return String(row[key]).toLowerCase().indexOf(searchKey) > -1;
          });
        });
      }
      return branches;
    },
  },
  methods: {
    storeCurrentBranch(branch) {
      this.$store.commit("SET_LOCKED_BLANCH", branch);
      this.$emit("closed");
    },
  },
};
</script>
<style lang="css">
.branches_search {
  padding: 4px 0 16px;
}
.branches_search form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
.branches_search .iconContainer {
  left: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: 48px;
  flex-basis: 48px;
  position: absolute;
  top: 12px;
}
.iconContainer .icon-search {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMjAgMTguNDY1TDE4LjQ2OCAyMGwtNS45ODItNS45OTZhNy43NjUgNy43NjUgMCAwMS00LjcxMSAxLjU5M0MzLjQ5NyAxNS41OTcgMCAxMi4wOTMgMCA3Ljc5MSAwIDMuNTA1IDMuNDk3IDAgNy43NzUgMGM0LjI3NyAwIDcuNzg5IDMuNTA1IDcuNzg5IDcuNzkxIDAgMS43NTMtLjU3OCAzLjM2LTEuNTYxIDQuNjY0TDIwIDE4LjQ2NXpNMi4xODIgNy43OWMwIDMuMSAyLjUxNSA1LjYyIDUuNTkzIDUuNjJzNS42MDYtMi41MiA1LjYwNi01LjYyYzAtMy4wODQtMi41MjgtNS42MDQtNS42MDYtNS42MDRTMi4xODIgNC43MDcgMi4xODIgNy43OXoiLz48L3N2Zz4=);
  margin: auto;
  background-repeat: no-repeat;
  height: 22px;
  width: 18px;
  min-height: 16px;
  min-width: 16px;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}
.branches_search input {
  border: 1px solid #d3d3d3;
  padding: 8px 40px 8px 48px;
  background-color: #fff;
  margin: 0;
  border-radius: 0;
  box-shadow: none;
  font: 14px/1.375 TheOutnetWebXL, Arial;
  height: 48px;
  width: 100%;
  outline: none;
}
.branches_list {
  max-height: 55vh;
  overflow-y: auto;
}
</style>
