import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    sideBarOpen: false,
    timeZone: localStorage.getItem("_tz") || "Africa/Kigali",
    today:
      localStorage.getItem("today") ||
      new Date()
        .toLocaleString("fr-CA", { timeZone: "Africa/Kigali" })
        .slice(0, 10),
    token: localStorage.getItem("token") || "",
    branch: JSON.parse(localStorage.getItem("__branch")) || {},
    user: {},
    dateFilters: [],
    isLoading: false,
    deleteAlert: {
      title: null,
      content: null,
      actionType: null,
      actionButton: null,
      classes: null,
      modalOpen: false,
    },
    routes: {},
    hasActiveModal: false,
    flashMessage: {},
    branches: [],
    settings: {
      site_address: null,
      site_name: null,
      currency: null,
      contact_one: null,
      app_phone: null,
      app_email: null,
      app_tin: null,
      site_logo: null,
    },
    notifications: {
      requisitions: 0,
      purchase_orders: 0,
      suppliers_payments: 0,
      pending_orders: 0,
    },
    accounts: [],
    paymentModes: [],
    voucherReference: null,
    voucherOrigin: null,
    dataFilters: {},
  },

  mutations: {
    PREVIEW_VOUCHER(state, playload) {
      if (playload) {
        state.voucherReference = playload.reference;
        state.voucherOrigin = playload.origin;
      } else {
        state.voucherReference = null;
        state.voucherOrigin = null;
      }
    },
    TOGGLE_SIDEBAR(state) {
      state.sideBarOpen = !state.sideBarOpen;
    },
    SET_PAYMENT_ACCOUNTS(state, accounts) {
      state.accounts = accounts;
    },
    SET_PAYMENT_MODES(state, modes) {
      state.paymentModes = modes;
    },
    REQUEST_RESOLVED(state) {
      state.isLoading = false;
      state.requestFlag = null;
      if (state.hasActiveModal) {
        state.hasActiveModal = false;
        document.querySelector("#alert-container").remove();
      }
    },
    REGISTER_CONFIRM_MODAL(state) {
      state.requestFlag = "HANDLING_ALERT_ACTION";
      state.hasActiveModal = true;
    },
    SET_APP_SETTINGS(state, settings) {
      state.settings = settings;
      localStorage.setItem("_tz", settings.timezone);
      localStorage.setItem("today", settings.today);
    },
    SET_LOCKED_BLANCH(state, branch) {
      if (branch != null)
        localStorage.setItem("__branch", JSON.stringify(branch));
      else localStorage.removeItem("__branch");
      state.branch = branch;
    },
    SET_REQUEST_FLAG(state, flag) {
      state.requestFlag = flag;
    },
    SET_FLASH_MESSAGE(state, message) {
      state.flashMessage = message;
    },
    SET_CURRENT_USER(state, user) {
      localStorage.removeItem("__branch");
      state.user = user;
      if (user.branch) {
        localStorage.setItem("__branch", JSON.stringify(user.branch));
      }
    },
    SET_BRANCHES(state, branches) {
      state.branches = branches;
    },
    SET_DATE_FILTERS(state, filters) {
      state.dateFilters = filters;
    },
    SET_DATA_FILTERS(state, filters) {
      state.dataFilters = { ...state.dataFilters, ...filters };
    },
    SET_MENU_NOTIFICATIONS(state, records) {
      state.notifications = { ...state.notifications, ...records };
    },
    REDUCE_NOTIFICATIONS(state, obj) {
      state.notifications[obj.key] -= obj.total || 1;
    },
  },
  actions: {
    logout() {
      return new Promise((resolve) => {
        localStorage.clear();
        resolve();
      });
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    user: (state) => state.user,
    branch: (state) => state.branch,
    requestFlag: (state) => state.requestFlag,
    modes: (state) => state.paymentModes || [],
    accounts: (state) => state.accounts || [],
  },
});
