export const branchMenus = [
  {
    title: "sale_items",
    icon: `<em class="icon ni ni-cart-fill"></em>`,
    url: { name: "BranchItems", params: {} },
    extras: [],
    alias: "Sale Items",
  },
  {
    title: "requisitions",
    icon: `<em class="icon ni ni-tranx"></em>`,
    url: { name: "" },
    alias: "Requisitions",
    extras: [],
    submenus: {
      auto_requisitions: { name: "AutoRequisitions" },
      placed_requisitions: { name: "Requests" },
    },
  },
  {
    title: "invoices",
    icon: `<em class="icon ni ni-file-docs"></em>`,
    url: { name: "BranchSalesInvoices", params: {} },
    extras: [],
    alias: "Invoices",
  },
  {
    title: "expenses",
    icon: `<em class="icon ni ni-view-col"></em>`,
    url: { name: "ExpenseCreator", params: { action: "new" } },
    extras: [],
    alias: "Expenses",
  },
  {
    title: "suppliers",
    icon: `<em class="icon ni ni-users-fill"></em>`,
    url: { name: "" },
    alias: "Suppliers",
    extras: [],
    submenus: {
      suppliers_list: { name: "Suppliers" },
      suppliers_balance: { name: "SupplierBalance" },
    },
  },
  {
    title: "purchases",
    icon: `<em class="icon ni ni-cart"></em>`,
    url: { name: "PurchaseCreator", params: {} },
    extras: [],
    alias: "Purchases",
  },
  {
    title: "physical_count",
    icon: `<em class="icon ni ni-view-panel-sq"></em>`,
    url: { name: "StockCount", params: {} },
    extras: [],
    alias: "Physical Count",
  },
  {
    title: "spoiled_items",
    icon: `<em class="icon ni ni-dot-box"></em>`,
    url: { name: "Spoiled", params: {} },
    extras: [],
    alias: "Spoiled Items",
  },
  {
    title: "adjustments",
    icon: `<em class="icon ni ni-block-over"></em>`,
    url: { name: "BranchAdjustments", params: {} },
    extras: [],
    alias: "Stock Adjustment",
  },
  {
    title: "stock_transfer",
    icon: `<em class="icon ni ni-block-over"></em>`,
    url: { name: "BranchTransfer", params: {} },
    extras: [],
    alias: "Stock Transfer",
  },
  {
    title: "stock_return",
    icon: `<em class="icon ni ni-block-over"></em>`,
    url: { name: "ReturnCreator", params: { action: "new" } },
    extras: [],
    alias: "Stock Transfer",
  },
  {
    title: "accounts_summary",
    icon: `<em class="icon ni ni-block-over"></em>`,
    url: { name: "BranchPaymentAccounts", params: {} },
    extras: [],
    alias: "Accounts Summary",
  },
  {
    title: "reports",
    icon: `<em class="icon ni ni-growth-fill"></em>`,
    url: { name: "GeneralReports", params: {} },
    extras: [],
    alias: "Reports",
  },
];
