<template>
  <div
    class="nk-sidebar nk-sidebar-fixed sidebar-open"
    :class="{ 'nk-sidebar-active': sideBarOpen }"
  >
    <div class="nk-sidebar-element nk-sidebar-head px-0 py-1">
      <div class="nk-sidebar-brand">
        <a href="javascript:void(0)" class="logo-link nk-sidebar-logo">
          <span
            v-if="!sideBarOpen"
            @click.prevent="$store.commit('TOGGLE_SIDEBAR')"
            class="nk-nav-toggle nk-quick-nav-icon d-xl-none"
          >
            <em class="icon ni ni-menu"></em>
          </span>
          <img
            v-else
            class="logo-light logo-img img-fluid"
            :src="`${publicPath}img/logo-mini.png`"
            alt="logo" />
          <img
            class="logo-dark logo-img img-fluid"
            :src="`${publicPath}img/logo.png`"
            alt="logo-dark"
        /></a>
      </div>
      <div class="nk-menu-trigger me-n2">
        <a
          href="#"
          class="nk-nav-toggle nk-quick-nav-icon d-xl-none"
          :class="{ 'toggle-active': sideBarOpen }"
          @click.prevent="$store.commit('TOGGLE_SIDEBAR')"
          ><em class="icon ni ni-arrow-left"></em></a
        ><a
          href="#"
          class="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex"
          :class="{ 'toggle-active': sideBarOpen }"
          @click.prevent="$store.commit('TOGGLE_SIDEBAR')"
          ><em class="icon ni ni-menu"></em
        ></a>
      </div>
    </div>
    <div class="nk-sidebar-element">
      <div class="nk-sidebar-content">
        <div class="nk-sidebar-menu" data-simplebar="init">
          <div class="simplebar-wrapper" style="margin: -16px 0px -40px">
            <div class="simplebar-height-auto-observer-wrapper">
              <div class="simplebar-height-auto-observer"></div>
            </div>
            <div class="simplebar-mask">
              <div class="simplebar-offset" style="right: 0px; bottom: 0px">
                <div
                  class="simplebar-content-wrapper"
                  style="height: 100%; overflow: hidden scroll"
                >
                  <div class="simplebar-content" style="padding: 16px 0px 40px">
                    <ul class="nk-menu" v-if="$helper.empty(loggedUser.branch)">
                      <template v-for="menu in menus">
                        <li
                          class="nk-menu-item has-sub"
                          v-if="
                            !$helper.empty(menu.submenus) &&
                            isMenuAccessible(
                              $helper.createTitleSlug(menu.title)
                            )
                          "
                          :key="menu.title"
                        >
                          <a
                            @click.prevent="showSubMenus(menu.title)"
                            href="javascript:void(0)"
                            :id="menu.title"
                            class="nk-menu-link nk-menu-toggle"
                            ><span class="nk-menu-icon"
                              ><em :class="`icon ${menu.icon}`"></em></span
                            ><span class="nk-menu-text text-capitalize">{{
                              $helper.capitalizify(menu.title) | trans
                            }}</span>
                            <span
                              class="nk-menu-badge"
                              v-if="hasNotifications(menu.notifications)"
                              >{{
                                totalNotifications(menu.notifications)
                              }}</span
                            >
                          </a>
                          <ul class="nk-menu-sub">
                            <template v-for="(item, i) in menu.submenus">
                              <li
                                class="nk-menu-item"
                                v-if="
                                  canView(
                                    [
                                      $helper.createTitleSlug(menu.title),
                                      'submenus',
                                      i,
                                    ].join('.')
                                  )
                                "
                                :key="i"
                              >
                                <router-link :to="item" class="nk-menu-link"
                                  ><span class="nk-menu-text text-capitalize">{{
                                    $helper.inputTitle(i) | trans
                                  }}</span></router-link
                                >
                              </li>
                            </template>
                          </ul>
                        </li>
                        <li
                          class="nk-menu-item"
                          v-else-if="
                            isMenuAccessible(
                              $helper.createTitleSlug(menu.title)
                            )
                          "
                          :key="menu.title"
                        >
                          <router-link
                            :to="menu.url"
                            class="nk-menu-link single-menu-item"
                            ><span class="nk-menu-icon"
                              ><em :class="`icon ni ${menu.icon}`"></em></span
                            ><span class="nk-menu-text text-capitalize">{{
                              menu.title | trans
                            }}</span></router-link
                          >
                        </li>
                      </template>
                      <li class="nk-menu-item" v-if="isMenuAccessible('pos')">
                        <a
                          :href="`${selfLocation}/pos`"
                          target="_blank"
                          class="nk-menu-link single-menu-item"
                          ><span class="nk-menu-icon"
                            ><svg
                              height="10px"
                              style="width: 18px"
                              viewBox="0 0 16 16"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M12 1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z"
                              ></path>
                              <path
                                d="M4 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-2zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-4z"
                              ></path></svg></span
                          ><span class="nk-menu-text text-capitalize"
                            >POS</span
                          ></a
                        >
                      </li>
                      <li
                        class="nk-menu-item"
                        v-if="isMenuAccessible('front_office')"
                      >
                        <a
                          :href="`https://sales.tameaps.com/?url=${
                            selfLocation + baseURL
                          }&token=${currentToken}`"
                          target="_blank"
                          class="nk-menu-link single-menu-item"
                          ><span class="nk-menu-icon"
                            ><em class="icon ni ni ni-cart"></em></span
                          ><span class="nk-menu-text text-capitalize"
                            >Front Office</span
                          ></a
                        >
                      </li>
                      <li
                        class="nk-menu-item"
                        v-if="isMenuAccessible('accommodation')"
                      >
                        <a
                          :href="`${selfLocation}/lodging-services`"
                          target="_blank"
                          class="nk-menu-link single-menu-item"
                          ><span class="nk-menu-icon"
                            ><svg
                              height="16px"
                              style="width: 16px"
                              viewBox="0 0 448 512"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                            >
                              <path
                                d="m128 148v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm140 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm-128 96h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm128 0h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm-76 84v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm76 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm180 124v36h-448v-36c0-6.6 5.4-12 12-12h19.5v-440c0-13.3 10.7-24 24-24h337c13.3 0 24 10.7 24 24v440h19.5c6.6 0 12 5.4 12 12zm-368.5-13h112.5v-67c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v67h112.5v-414l-288.5-1z"
                              ></path></svg></span
                          ><span class="nk-menu-text text-capitalize"
                            >Accommodation</span
                          ></a
                        >
                      </li>
                      <li
                        class="nk-menu-item"
                        v-if="isMenuAccessible('assets')"
                      >
                        <a
                          :href="`${selfLocation}/assets/dashboard`"
                          target="_blank"
                          class="nk-menu-link single-menu-item"
                          ><span class="nk-menu-icon"
                            ><svg
                              height="16px"
                              style="width: 16px"
                              viewBox="0 0 640 512"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                            >
                              <path
                                d="M104 48C117.3 48 128 58.75 128 72C128 85.25 117.3 96 104 96H24C10.75 96 0 85.25 0 72C0 58.75 10.75 48 24 48H104zM64 128C77.25 128 88 138.8 88 152C88 165.3 77.25 176 64 176H24C10.75 176 0 165.3 0 152C0 138.8 10.75 128 24 128H64zM192 80C192 53.49 213.5 32 240 32H360C386.5 32 408 53.49 408 80V120H392C374.3 120 360 134.3 360 152V192H240V152C240 134.3 225.7 120 208 120H192V80zM440 120H416V192H440C466.5 192 488 213.5 488 240V360C488 386.5 466.5 408 440 408H320C293.5 408 272 386.5 272 360V336H256V360C256 386.5 234.5 408 208 408H88C61.49 408 40 386.5 40 360V240C40 213.5 61.49 192 88 192H112V120H88C61.49 120 40 98.51 40 72C40 45.49 61.49 24 88 24H112C138.5 24 160 45.49 160 72V120H168C185.7 120 200 134.3 200 152V192H328V152C328 134.3 342.3 120 360 120H440zM104 464C117.3 464 128 474.7 128 488C128 501.3 117.3 512 104 512H24C10.75 512 0 501.3 0 488C0 474.7 10.75 464 24 464H104zM64 384C77.25 384 88 394.8 88 408C88 421.3 77.25 432 64 432H24C10.75 432 0 421.3 0 408C0 394.8 10.75 384 24 384H64z"
                              /></svg
                          ></span>
                          <span class="nk-menu-text text-capitalize"
                            >Assets</span
                          ></a
                        >
                      </li>
                    </ul>
                    <ul class="nk-menu" v-else>
                      <template v-for="menu in branchMenus">
                        <li
                          class="nk-menu-item"
                          :key="menu.title"
                          v-if="
                            !menu.submenus && canAccessBranchMenu(menu.title)
                          "
                        >
                          <router-link :to="menu.url" class="nk-menu-link"
                            ><span
                              class="nk-menu-icon"
                              v-html="menu.icon"
                            ></span
                            ><span class="nk-menu-text">{{
                              menu.alias
                            }}</span></router-link
                          >
                        </li>
                        <li
                          class="nk-menu-item has-sub"
                          v-else-if="canAccessBranchMenu(menu.title)"
                          :key="menu.title"
                        >
                          <a
                            href="javascript:void(0)"
                            :id="menu.title"
                            @click.prevent="showSubMenus(menu.title)"
                            class="nk-menu-link nk-menu-toggle"
                            ><span
                              class="nk-menu-icon"
                              v-html="menu.icon"
                            ></span
                            ><span class="nk-menu-text text-capitalize">{{
                              menu.alias
                            }}</span></a
                          >
                          <ul class="nk-menu-sub">
                            <template v-for="(item, i) in menu.submenus">
                              <li
                                class="nk-menu-item"
                                :key="i"
                                v-if="canAccessBranchMenu(menu.title, i)"
                              >
                                <router-link :to="item" class="nk-menu-link"
                                  ><span class="nk-menu-text text-capitalize">{{
                                    $helper.inputTitle(i) | trans
                                  }}</span></router-link
                                >
                              </li>
                            </template>
                          </ul>
                        </li>
                      </template>
                      <li
                        class="nk-menu-item"
                        v-if="canAccessBranchMenu('pos')"
                      >
                        <a
                          :href="`${selfLocation}/pos`"
                          target="_blank"
                          class="nk-menu-link single-menu-item"
                          ><span class="nk-menu-icon"
                            ><svg
                              height="10px"
                              style="width: 18px"
                              viewBox="0 0 16 16"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M12 1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z"
                              ></path>
                              <path
                                d="M4 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-2zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-4z"
                              ></path></svg></span
                          ><span class="nk-menu-text text-capitalize"
                            >POS</span
                          ></a
                        >
                      </li>
                      <li
                        class="nk-menu-item"
                        v-if="canAccessBranchMenu('accommodation')"
                      >
                        <a
                          :href="`${selfLocation}/lodging-services`"
                          target="_blank"
                          class="nk-menu-link single-menu-item"
                          ><span class="nk-menu-icon"
                            ><svg
                              height="16px"
                              style="width: 16px"
                              viewBox="0 0 448 512"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                            >
                              <path
                                d="m128 148v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm140 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm-128 96h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm128 0h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm-76 84v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm76 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm180 124v36h-448v-36c0-6.6 5.4-12 12-12h19.5v-440c0-13.3 10.7-24 24-24h337c13.3 0 24 10.7 24 24v440h19.5c6.6 0 12 5.4 12 12zm-368.5-13h112.5v-67c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v67h112.5v-414l-288.5-1z"
                              ></path></svg></span
                          ><span class="nk-menu-text text-capitalize"
                            >Accommodation</span
                          ></a
                        >
                      </li>
                      <li
                        class="nk-menu-item"
                        v-if="canAccessBranchMenu('front_office')"
                      >
                        <a
                          :href="`https://sales.tameaps.com/?url=${
                            selfLocation + baseURL
                          }&token=${currentToken}`"
                          class="nk-menu-link single-menu-item"
                          target="_blank"
                          ><span class="nk-menu-icon"
                            ><em class="icon ni ni ni-cart"></em></span
                          ><span class="nk-menu-text text-capitalize"
                            >Front Office</span
                          ></a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="simplebar-placeholder"
              style="width: auto; height: 585px"
            ></div>
          </div>
          <div
            class="simplebar-track simplebar-horizontal"
            style="visibility: hidden"
          >
            <div
              class="simplebar-scrollbar"
              style="width: 0px; display: none"
            ></div>
          </div>
          <div
            class="simplebar-track simplebar-vertical"
            style="visibility: visible"
          >
            <div
              class="simplebar-scrollbar"
              style="
                height: 81px;
                transform: translate3d(0px, 138px, 0px);
                display: block;
              "
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="nk-sidebar-overlay"
      data-target="sidebarMenu"
      v-if="sideBarOpen"
    ></div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { menus } from "@/common/menus.js";
import { baseURL } from "@/_helpers/utils.js";
import { branchMenus } from "./branch-menus.js";

export default {
  name: "SideBar",
  data: () => ({
    menus,
    baseURL,
    branchMenus,
  }),
  watch: {
    $route() {
      var activeLink = document.querySelector(
        "a[href='" + location.pathname + "']"
      );
      if (activeLink) {
        let parent = activeLink.closest(".nk-menu-item.has-sub");
        if (parent) {
          parent.classList.add("active");
        }
      }
    },
  },
  created() {
    this.$http.get("shared/notifications").then((response) => {
      this.$store.commit("SET_MENU_NOTIFICATIONS", response.data.records);
    });
  },
  computed: {
    ...mapState({
      notifications: (state) => state.notifications,
      sideBarOpen: (state) => state.sideBarOpen,
    }),
    selfLocation() {
      return window.location.origin;
    },
    currentToken() {
      return localStorage.getItem("token");
    },
    isReady() {
      return this.loggedUser && Object.keys(this.loggedUser).length > 0;
    },
    branchPermissions() {
      return this.isReady && this.loggedUser.role.branch_permissions;
    },
  },
  methods: {
    canAccessBranchMenu(menu, submenu = null) {
      if (!submenu) {
        return (
          this.branchPermissions[menu] &&
          this.branchPermissions[menu].accessible == true
        );
      } else {
        return (
          this.branchPermissions[menu] &&
          this.branchPermissions[menu][submenu] &&
          this.branchPermissions[menu][submenu].includes("R")
        );
      }
    },
    showSubMenus(id) {
      var parent = document.querySelector(`a#${id}`).parentElement;
      [...document.querySelectorAll(".nk-menu-item.has-sub")].forEach((li) => {
        if (li != parent) li.classList.remove("active");
      });
      parent.classList.toggle("active");
    },
    hasNotifications(keys) {
      return keys.some((item) => this.notifications[item] != 0);
    },
    totalNotifications(keys) {
      return keys.reduce(
        (prev, next) => prev + Number(this.notifications[next]),
        0
      );
    },
    isMenuAccessible(menu) {
      if (this.userRole == "ADMINISTRATOR") return true;
      return (
        this.userPermissions[menu] &&
        this.userPermissions[menu].accessible == true
      );
    },
  },
  mounted() {
    const routes = {};
    branchMenus.forEach((menu) => {
      if (!menu.submenus) {
        routes[menu.url.name] = menu.title;
      } else {
        for (let item in menu.submenus) {
          routes[menu.submenus[item].name] = `${menu.title}.${item}`;
        }
      }
    });
    this.$store.state.routes = routes;
  },
};
</script>
<style>
.logo-img {
  max-height: 45px;
}

@media (min-width: 1200px) {
  .nk-sidebar {
    width: 200px;
  }

  .preloader {
    left: 200px;
    top: 63px;
  }

  .preloader .preloader-inner {
    left: calc(50% - 200px);
  }
}

@media (max-width: 1200px) {
  .logo-img {
    max-height: unset;
  }

  .nk-sidebar-overlay {
    z-index: -1;
    left: 200px;
  }
}

.nk-sidebar {
  width: 200px;
}

.nk-sidebar + .nk-wrap,
.nk-sidebar-overlay + .nk-wrap {
  padding-left: 200px;
}

.nk-sidebar + .nk-wrap > .nk-header-fixed,
.nk-sidebar-overlay + .nk-wrap > .nk-header-fixed {
  left: 200px;
}

.nk-menu-icon .icon {
  font-size: 18px;
}

.nk-menu-item {
  padding: 3px 0;
}

.nk-menu-icon {
  width: 22px;
}

.nk-menu-link {
  font-size: 13px;
  font-weight: 500;
}

.nk-menu-link,
.nk-menu-toggle:after {
  color: #00123e;
}

.active .nk-menu-toggle:after,
.has-sub:hover .nk-menu-toggle:after {
  color: #fff;
}

.nk-sidebar {
  background: #eee;
  background-color: #f7f7fe;
}

.nk-header.is-light:not([class*="bg-"]) {
  background: #f7f7f7;
}

.single-menu-item.router-link-exact-active,
.nk-menu-link:hover,
.active > .nk-menu-link {
  color: #fff;
  background: #00123e;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.nk-menu-link:hover .nk-menu-icon,
.nk-menu-item.active > .nk-menu-link .nk-menu-icon,
.single-menu-item.router-link-exact-active > .nk-menu-icon,
.nk-menu-item.current-menu > .nk-menu-link .nk-menu-icon {
  color: #fff;
}

.nk-menu-link,
.nk-menu-toggle:after {
  color: #00123e;
}

.nk-sidebar-head {
  width: 200px;
}

.nk-sidebar .nk-menu > li .nk-menu-sub .nk-menu-link {
  padding-left: 20px;
  padding-right: 0;
}

.nk-sidebar-logo {
  width: 200px;
}

@media (max-width: 768px) {
  .nk-sidebar + .nk-wrap,
  .nk-sidebar-overlay + .nk-wrap {
    padding-left: 0 !important;
  }

  .nk-sidebar {
    width: 0 !important;
    z-index: 9999;
  }
  .nk-sidebar.nk-sidebar-active {
    width: 200px !important;
    z-index: 1013;
  }
  .logo-light {
    opacity: 1;
  }

  .logo-dark {
    opacity: 0;
  }

  .nk-sidebar-logo,
  .nk-sidebar-head {
    width: 60px;
  }

  .nk-header.is-light:not([class*="bg-"]) {
    background: #fff !important;
  }

  .nk-sidebar + .nk-wrap > .nk-header-fixed {
    left: 0px;
  }

  .nk-quick-nav {
    margin: 0;
    margin-right: 7px;
  }
}
</style>
